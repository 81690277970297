<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技&Rust中文社区，线上技术分享会成功举办
              </p>
              <div class="fst-italic mb-2">2022年3月6日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >技术分享</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >直播宣讲</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/06/新闻封面.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    很多人会问：
                  </p>
                  <p>
                    未来几年，国内量化机构都会考虑用 Rust 吗？
                  </p>
                  <p>
                    Rust 在量化领域如何应用？又有什么独特优势？
                  </p>
                  <p>
                    就高频量化交易系统而言，Rust 比 C++ 强吗？
                  </p>
                  <p>
                    如何看待 Rust 的应用前景？
                  </p>
                  <p>
                    ......
                  </p>
                  <p>
                    为满足大家的好奇心，3月6日，非凸科技与 Rust 中文社区联合举办了一场线上技术分享会。非凸科技北京分公司负责人朱为老师就“国内外量化发展现状、Rust在量化领域应用、典型工作场景以及招聘职位等方面进行了分享。满满干货，值得回看！
                  </p>
                  <h4 class="my-4">一、国内外量化发展现状</h4>
                  <p>
                    目前，国内证券类私募基金规模达到6.34万亿元，其中量化私募基金规模估算约1.5万亿元，占比24%。而全球对冲基金的管理资产总规模有超4万亿美元，其中桥水的资产规模就达1501亿美元。除此之外，国内117家百亿私募中只有28家量化私募，而全球对冲基金规模T0P10中就有8家量化基金。朱为老师表示，相较之下，国内量化交易市场发展空间还很巨大，未来10年将是A股市场算法交易发展的黄金期。
                  </p>
                  <p>
                    非凸科技是首家全面使用 Rust 语言的金融科技公司，目前正基于 Rust 生态，结合机器学习和深度学习等新兴技术，打造高效率、低延迟、高可靠、全内存高频交易平台，为券商、量化私募等大型金融机构提供优质的算法服务。
                  </p>
                  <p>
                    非凸科技自主研发的智能算法，主要为机构投资者提供以成交为目的的自动化交易执行。其主要优势在于能够跑赢市场3-5BP（基点），支持本地化部署，更好地保证交易的隐秘性、安全性以及审计监管等。
                  </p>
                  <h4 class="my-4">二、如何用 Rust 来实现高频量化交易系统？</h4>
                  <p >
                    高频量化交易系统的设计目标是：高性能、低延迟、高可靠、开发效率高，那为何用 Rust 才能实现呢？
                  </p>
                  <p >
                    “第一，高性能，需要超大数据集离线训练与实时回测，以及上万种标的物行情预测、实时报撤单等；第二，低延迟，需要计算通信延迟均在纳秒级优化，充分考虑CPU Cache优化细节；第三，高可靠，需要线上免维护（很多时候是私有部署），杜绝崩溃等非正常退出（这也是选择Rust的原因）；第四，开发效率高，则需要有较好的生态和基础库，无须造轮子，还需要因子&模型&策略在/离线使用同一份代码，以及无须关注操作系统及硬件差异等。”朱为老师表示。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/06/直播00.jpg"
                      alt="直播现场"
                    />
                  </figure>
                  <p >
                    然而，用 Rust 来实现高频量化交易系统的过程，可能会面临这样的一些问题，比如 Rust 如何完成行情的接入？低延迟如何体现？如何基于共享内存队列实现RPC？交易引擎是用异步编程还是用同步编程？采用Rust进行模型训练与预测有什么优势？对此，朱为老师一一作出了回答，最后还向大家展示了如何用Rust写网格交易策略。
                  </p>
                  <h4 class="mb-4">三、QA环节</h4>
                  <p >
                    本次技术分享会圆满结束，收获了很多点赞和讨论。有人直呼：正想了解 Rust 量化方面的应用，想什么来什么，爱了爱了！
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/06/QA.jpg"
                      alt="QA环节"
                    />
                  </figure>
                  <p>
                      非凸科技是 Rust 量化先行者，追求高效和极致。我们聘请优秀的人，采用先进的技术，建立卓越的团队，成就不平凡的非凸，为量化行业带来效率价值最大化！欢迎加入非凸，为自己拼搏，也成就彼此！
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News01",
};
</script>

<style></style>
